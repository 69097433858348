import React from 'react';

import { Text } from '@chakra-ui/react';
import { useMediaQuery } from '@material-ui/core';
import styled from 'styled-components';

// components
import { Typography } from '@packages/deprecated-universal-component-library/src/atoms/typography';
import { above } from '@packages/deprecated-universal-component-library/src/utils/mediaTemplate';

import { alleColors } from '../../colorPalette';
import { AlleLogo } from '../../icons/alleLogo';
import { MegaPhone } from '../../icons/megaphone';

const Wrapper = styled.div`
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  flex: 1;
  min-height: 650px;
  width: 100%;
  box-sizing: border-box;
  max-width: unset;
  padding: unset;
  position: relative;
  background: #efe1dd;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const AlleDownPage: React.FunctionComponent = () => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const isExtraLarge = useMediaQuery('(min-width: 1440px)');

  return (
    <Wrapper>
      <div
        css={`
          padding: 16px;
        `}
      >
        <AlleLogo />
      </div>

      <Container>
        <div
          css={`
            position: absolute;
            bottom: -3px;
            width: 100%;
            box-sizing: border-box;
            height: 140px;

            ${above.tablet`
              height: 200px;
            `}
          `}
        >
          {isMobile ? (
            <svg
              viewBox="0 0 375 202"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_1002_2675_1102398123"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="375"
                height="202"
              >
                <rect width="375" height="202" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_1002_2675_1102398123)">
                <path
                  d="M687.225 20.3245L626.227 21.5411L560.792 20.3245L521.966 15.8955C489.058 12.1415 455.886 11.2551 422.825 13.2463L378.019 12.3088L329.664 17.0173L318.819 17.5782C297.425 18.6847 275.975 17.4434 254.852 13.8763C245.317 12.2661 235.626 11.7787 225.977 12.4242L218.388 12.9318C203.172 13.9497 187.891 13.3514 172.801 11.1469L120.844 3.55648C104.67 1.19353 88.2751 0.721222 71.9912 2.14911L43.9542 4.6076C18.3592 6.85195 -7.37451 7.04308 -33 5.17915V117.545L-17.4731 140.404V171C-17.4731 184.255 -6.72791 195 6.52692 195H597.347C610.602 195 621.347 184.255 621.347 171V144.19L689 125.117L687.225 20.3245Z"
                  fill="#FBF6F5"
                />
              </g>
            </svg>
          ) : isExtraLarge ? (
            <svg
              viewBox="0 0 1440 801"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_1002_2671_1230981234"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                height="801"
                width="1440"
              >
                <rect width="1440" height="801" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_1002_2671_1230981234)">
                <path
                  d="M1588.5 84.8664L1451 91L1379.04 88.0077C1328.82 85.9192 1278.95 78.6021 1230.24 66.1751L1214 62.0331C1141.68 43.5804 1066.48 39.2171 992.5 49.1814C925.455 46.0439 858.287 51.6882 792.705 65.9706L782.5 68.1931C718.363 75.6125 653.413 71.3615 590.79 55.6458L553.947 46.3995C537.379 42.2416 520.362 40.139 503.28 40.139C488.128 40.139 473.01 38.6936 458.133 35.8226L329.566 11.0123C291.662 3.69777 253.011 1.00831 214.459 3.00282L108 8.51065H-35V575.005L0 690.253V941.5C0 954.755 10.7451 965.5 24 965.5H1416C1429.25 965.5 1440 954.755 1440 941.5V709.342L1592.5 613.183L1588.5 84.8664Z"
                  fill="#FBF6F5"
                />
              </g>
            </svg>
          ) : (
            <svg
              viewBox="0 0 1440 401"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_1002_2671_489249240"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                height="401"
                width="1440"
              >
                <rect width="1440" height="401" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_1002_2671_489249240)">
                <path
                  d="M1588.5 84.8664L1451 91L1379.04 88.0077C1328.82 85.9192 1278.95 78.6021 1230.24 66.1751L1214 62.0331C1141.68 43.5804 1066.48 39.2171 992.5 49.1814C925.455 46.0439 858.287 51.6882 792.705 65.9706L782.5 68.1931C718.363 75.6125 653.413 71.3615 590.79 55.6458L553.947 46.3995C537.379 42.2416 520.362 40.139 503.28 40.139C488.128 40.139 473.01 38.6936 458.133 35.8226L329.566 11.0123C291.662 3.69777 253.011 1.00831 214.459 3.00282L108 8.51065H-35V575.005L0 690.253V941.5C0 954.755 10.7451 965.5 24 965.5H1416C1429.25 965.5 1440 954.755 1440 941.5V709.342L1592.5 613.183L1588.5 84.8664Z"
                  fill="#FBF6F5"
                />
              </g>
            </svg>
          )}
        </div>

        <div
          css={`
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            z-index: 10;
            margin-top: -96px;
          `}
        >
          <MegaPhone />

          <Text as="h1" textStyle="Headline/Large/Primary medium" m="16px 0">
            Allē Is Temporarily Unavailable
          </Text>

          <Typography
            preset={{
              s: 'body-L-regular',
              m: 'headline-S-graphik',
              l: 'headline-S-graphik',
            }}
            component="p"
            css={`
              max-width: 350px;
              padding: 16px;

              ${above.tablet`
                max-width: 480px;
                padding: unset;
              `}
            `}
          >
            We are aware of the issue and are working to restore services as
            quickly as possible. Please try back later. We apologize for any
            inconvenience.
          </Typography>
        </div>
      </Container>

      <div
        css={`
          box-sizing: border-box;
          min-height: 256px;
          width: 100%;
          padding: 64px 32px;
          background: black;
        `}
      >
        <div
          css={`
            margin: auto;
            max-width: 1200px;
          `}
        >
          <AlleLogo dark={true} />

          <Typography
            component="p"
            preset="body-M-regular"
            css={`
              margin-top: 46px;
              line-height: 24px;
              color: ${alleColors.gray[400]};
            `}
          >
            © 2022 AbbVie. All rights reserved. All trademarks are the property
            of their respective owners.
            <br />
            Allergan Aesthetics reserves the right to alter or cancel the
            program with no advance notice or obligation.
            <br />
            PRT130435-v6 11/22
          </Typography>
        </div>
      </div>
    </Wrapper>
  );
};

export { AlleDownPage };
